a {
  text-decoration: none;
}

.container {
  max-width: none !important;
  display: flex;
}

.nutritionist {
  display: flex;
}

.nutritionist__content {
  width: 100%;
  height: 100vh;
  background-color: white;
  padding-left: 112px;
  padding-right: 112px;
}

@media screen and (max-width: 1100px) {
  .nutritionist__content {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.nutritionist__content__lists {
  display: flex;
}

.nutritionist__content__header {
  display: flex;
  justify-content: space-between;
  padding-top: 38px;
  padding-bottom: 3.5px;
}

.nutritionist__content__header h1 {
  font-size: 24px;
  font-weight: 700;
}

.simple-pagination {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: space-between;
  width: 100%;
}

.nutritionist__nutritionist__users__footer button:hover {
  border: 0;
  font-size: 12px;
}

.nutritionist__nutritionist__users__footer button:disabled {
  background-color: white;
  border: 0;
  color: #a7a4a4 !important;
  font-size: 12px;
}


.nutritionist__nutritionist__users__footer button:active {
  background-color: rgb(244, 244, 244) !important;
  border: 0;
  color: #818181 !important;
  font-size: 12px;
}

.nutritionist__foodstuffs__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nutritionist__foodstuffs__header h3 {
  display: flex;
  font-size: 16px;
  color: #38383E;
  margin: 0;
  font-weight: 600;
}

.nutritionist__foodstuffs__header__actions button {
  height: 43px;
}

.users__title h3 {
  font-size: 20px;
  padding-top: 12px;
  font-weight: 600;
  height: 43px;
}

.nutritionist__foodstuffs__header__title h3 {
  font-size: 20px;
  padding-top: 4px;
  font-weight: 600;
}

.nutritionist__content__header__title {
  width: 60%;
  padding-top: 8px;
}

.nutritionist__content__header__title h1 {
  font-size: 24px;
}

.nutritionist__content__header__search {
  display: flex;
  justify-content: space-between;
  margin-right: 24px;
}

.nutritionist__content__header__me img {
  width: 40px;
  height: 40px;
  border-radius: 100px;
}

.appointments__header__actions {
  min-width: max-content;
  display: flex;
}

.appointments__header__actions__agenda {
  padding-left: 12px;
  display: flex;
  flex-direction: row;
}

.appointments__header__actions__agenda button {
  border-radius: 8px;
  padding: 0;
  padding-left: 12px;
  padding-right: 12px;
  height: 39px;
  font-size: 14px;
  display: flex;
}

.appointments__header__actions__agenda img {
  width: 20px;
  height: 20px;
  margin-top: 9px;
}

.appointments__header__actions__agenda p {
  padding-left: 8px;
  font-size: 14px;
  padding-top: 10px;
}

.appointments__column__patient {
  display: flex;
}

.appointments__column__patient p {
  padding-left: 12px;
  align-items: center;
  display: flex;
  padding-top: 3px;
}

.appointments__column__patient-icon {
  width: 40px;
  height: 40px;
  align-items: center;
  display: flex;
  background-color: #FFF3F5;
  border-radius: 100%;
}

.appointments__column__patient-icon img {
  height: 40px;
  width: 40px;
  border-radius: 100%;
}

.foodstuffs__search input.form-control {
  height: 38px;
}

.nutritionist__users__footer {
  display: flex;
  padding-left: 24px;
  padding-right: 24px;
  justify-content: center;
  align-items: center;
  height: 94px;
  width: 100%;
}

.simple-pagination {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: space-between;
  width: 100%;
}

.nutritionist__users__footer button:hover {
  border: 0;
  font-size: 12px;
}

.nutritionist__users__footer button:disabled {
  background-color: white;
  border: 0;
  color: #a7a4a4 !important;
  font-size: 12px;
}


.nutritionist__users__footer button:active {
  background-color: rgb(244, 244, 244) !important;
  border: 0;
  color: #818181 !important;
  font-size: 12px;
}

.nutritionist__users__header {
  background-color: #FAFAFA;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  width: 100%;
  height: 44px;
  display: flex;
  padding-top: 15px;
  padding-bottom: 8px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: #868693;
  border-bottom: 1px solid #F3F3F4;
}

.nutritionist__users__header__image {
  width: 8%;
  text-align: left;
  padding-left: 20px;
}

.nutritionist__users__header__name {
  width: 25%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.nutritionist__users__header__birthdate {
  width: 20%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.nutritionist__users__header__height {
  width: 25%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.nutritionist__users__header__weight {
  width: 25%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.nutritionist__users__header__actions {
  width: 7%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.appointments__table {
  margin-top: 20px;
}

.appointments__chevron {
  width: 7%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
}

.appointments__chevron button {
  background-color: transparent;
  border: 0;
}

.appointments__chevron button:hover {
  background-color: #FFF3F5;
  border: 0;
}

.appointments__chevron button:active {
  background-color: #fceef0 !important;
  border: 0 !important;
}

.agenda-dropdown.show {
  display: block;
  margin-top: 47px !important;
}

.agenda-dropdown {
  position: absolute;
  background-color: white;
  box-shadow: 0px 7px 29px rgba(100, 100, 111, 0.20) !important;
  z-index: 1;
  border-radius: 10px;
  flex-direction: column;
  margin-left: -179px !important;
  min-width: max-content;
  padding: 0;
  border: 1px solid #F3F3F4;
}

.agenda-dropdown__item {
  border: 0;
  width: 100%;
  color: #38383E;
  font-size: 14px;
  padding: 0;
  border-bottom: 1px solid #F3F3F4;
  min-height: max-content;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 13.5px;
  padding-bottom: 10.5px;
}

.manual-booking-modal .modal-content {
  max-width: 400px;
}

.manual-booking-modal__description {

}

.manual-booking-modal__description p {
  color: #7A7A86;
  font-size: 14px;
}

.manual-booking-modal__form {
  padding-top: 20px;
}

.manual-booking-modal__actions {
  padding-top: 24px;
  display: flex;
}

.manual-booking-modal__button {
  width: 50%;
}

.manual-booking-modal__button button {
  border-radius: 12px;
  height: 43px;
}

.manual-booking-modal__button:first-of-type {
  margin-right: 24px;
}

.manual-booking-modal__input:first-of-type {
  margin-top: 0;
}

.manual-booking-modal__input {
  margin-top: 12px;
  border: 1px solid #D9D9DE !important;
}

.manual-booking-modal__dropdown {
  margin-top: 12px;
}

.manual-booking-modal__availabilities {
  padding-top: 12px;
}

.manual-booking-modal__availabilities__title {
  color: #38383E;
  font-size: 14px;
  font-weight: 600;
}

.manual-booking-modal__availabilities__content {
  display: flex;
  margin-top: 10px;
  max-width: 352px;
  flex-wrap: wrap;
  justify-content: space-between;
  max-height: 200px;
  overflow: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  border-radius: 12px;
}

.manual-booking-modal__availability {
  width: 85px;
  border-radius: 12px;
  background-color: #FFF3F5;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 51px;
  margin-bottom: 4px;
}

.manual-booking-modal__availability:hover {
  background-color: #FCEEF0;
}

.manual-booking-modal__availability:active {
  background-color: #fee8eb;
}

.manual-booking-modal__availability:last-of-type {
}

.manual-booking-modal__availability__wrapper:last-of-type {
  /* padding-left: 4px; */
  /* flex-grow: 0.925; */
}

.manual-booking-modal__availability p {
  font-size: 14px;
  color: #8C4854;
  font-weight: bold;
  padding-top: 3px;
}

.manual-booking-modal__availability--selected {
  background-color: #FE8398;
}

.manual-booking-modal__availability--selected:hover {
  background-color: #ff7b91;
}

.manual-booking-modal__availability--selected:active {
  background-color: #ff7088;
}

.manual-booking-modal__availability--selected p {
  color: white;
  font-weight: 600;
}

.manage-availability-modal .modal-content {
  max-width: 400px;
}

.manage-availability-modal__description {

}

.manage-availability-modal__description p {
  color: #7A7A86;
  font-size: 14px;
}

.manage-availability-modal__actions {
  padding-top: 24px;
  display: flex;
}

.manage-availability-modal__button {
  width: 50%;
}

.manage-availability-modal__button button {
  border-radius: 12px;
  height: 43px;
}

.manage-availability-modal__button:first-of-type {
  margin-right: 24px;
}

.manage-availability-modal__week {
  padding-top: 20px;
}

.manage-availability-modal__week__title {
  color: #38383E;
  font-size: 14px;
  font-weight: 600;
}

.manage-availability-modal__days {
  display: flex;
  padding-top: 12px;
}

.manage-availability-modal__day {
  border-radius: 100%;
  background-color: #FFF3F5;
  width: 42px;
  height: 42px;
  margin-right: 8px;
}

.manage-availability-modal__day:hover {
  background-color: #FCEEF0;
}

.manage-availability-modal__day:active {
  background-color: #fee8eb;
}

.manage-availability-modal__day {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #8C4854;
  font-weight: 600;
  padding-top: 2px;
}

.manage-availability-modal__day--selected {
  background-color: #FE8398;
  color: white;
}

.manage-availability-modal__day--selected:hover {
  background-color: #ff7b91;
}

.manage-availability-modal__day--selected:active {
  background-color: #ff7088;
}

.manage-availability-modal__period {
  display: flex;
  margin-top: 12px;
}

.manage-availability-modal__input {
  border: 1px solid #D9D9DE !important;
}

.manage-availability-modal__period:first-of-type {
  margin-top: 0;
}

.manage-availability-modal__periods {
  padding-top: 24px;
}

.manage-availability-modal__periods__title {
  color: #38383E;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}

.manage-availability-modal__periods__title__actions {
  padding-right: 0px;
  display: flex;
}

.manage-availability-modal__periods__title__action {
  width: 20px;
  height: 20px;
  background-color: #FE8398;
  text-align: center;
  border-radius: 24px;
  color: white;
}

.manage-availability-modal__periods__title__action a {
  color: white;
  width: 100%;
  height: 100%;
}

.manage-availability-modal__periods__title__action:first-of-type {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-left: 4px;
}

.manage-availability-modal__periods__title__action:last-of-type {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-right: 4px;
}

.manage-availability-modal__periods__title__action:hover {
  background-color: #f17489;
}

.manage-availability-modal__periods__title__action:active {
  background-color: #e96d81;
}

.manage-availability-modal__periods__content {
  padding-top: 12px;
}

.manage-availability-modal__period p {
  /* font-size: 12px; */
}

.manage-availability-modal__period__divisor {
  padding-top: 22px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 12px;
}

.appointments__empty {
  border: 2px solid #F3F3F4;
  border-radius: 16px;
  padding: 20px;
  margin-top: 16px;
  height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.appointments__empty__icon {
  display: flex;
}

.appointments__empty__title {
  padding-top: 24px;
}

.appointments__empty__title h2 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.appointments__empty__description {
  padding-top: 8px;
  color: #5F5F68;
  font-size: 16px;
  max-width: 480px;
  text-align: center;
}

.appointments__empty__button {
  padding-top: 24px;
}

.appointments__empty__button button {
  border-radius: 8px;
  font-size: 14px;
  padding-left: 12px;
  padding-right: 12px;
}
